import MicroModal from "micromodal";
import { backfaceFixed } from "javascripts/backface-fixed";

const defaultOptions = {
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
  disableFocus: true,
  disableScroll: true,
  debugMode: false,
  onShow: () => {
    backfaceFixed(true);
  },
  onClose: (_modal, _activeElement, originalEvent) => {
    // NOTE: #10379 MicroModal.closeで閉じられた場合はoriginalEventがない
    if (originalEvent) {
      const targetElement = originalEvent.target;
      // NOTE: #10379 data-micromodal-closeが付いたDOMのclickイベントの伝搬がmicromodal内で止められてしまっているのでここで計測発火
      if (targetElement.matches(".js-click-count-micromodal-close")) {
        // eslint-disable-next-line no-undef
        onclickcatalyst_clickcount(targetElement.dataset.clickArea, targetElement.dataset.event);
      }
    }
    backfaceFixed(false);
  },
};

const micromodal = (id, options = {}) => {
  const element = document.getElementById(id);
  if (element) {
    const mergedOptions = Object.assign({}, defaultOptions, options);
    selfClosing();
    MicroModal.show(id, mergedOptions);
    adjustFloatingHeight(id);
  }
};

// NOTE: Modal内でModalを開く場合は閉じてから開く
const selfClosing = () => {
  const open = document.querySelector(".c-micromodal.is-open");
  if (open !== null) {
    MicroModal.close(open.id);
  }
};

const bindMicromodalEventTrigger = (element = document) => {
  const micromodalTrigger = "data-micromodal-trigger";
  const links = element.querySelectorAll(`[${micromodalTrigger}]`);
  links.forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      const id = link.getAttribute(micromodalTrigger);
      micromodal(id);
    });
  });
};

// NOTE: コンテンツ量が少ない場合に、最下部に配置するため
const adjustFloatingHeight = (id) => {
  const modal = document.getElementById(id);
  if (modal) {
    const floatingElement = modal.querySelector(".js-micromodal-floating");
    const sectionElement = modal.querySelector(".c-micromodal__section");
    if (floatingElement && sectionElement) {
      const floatingHeight = floatingElement.offsetHeight;
      if (document.documentElement.classList.contains("SmartView")) {
        sectionElement.style.minHeight = `calc(100% - ${floatingHeight}px)`;
      } else {
        sectionElement.style.minHeight = `calc(78vh - ${floatingHeight}px)`;
      }
    }
  }
};

export { micromodal, bindMicromodalEventTrigger };
